// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-review-js": () => import("./../src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-others-js": () => import("./../src/pages/others.js" /* webpackChunkName: "component---src-pages-others-js" */),
  "component---src-pages-reviews-js": () => import("./../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */)
}

